<template>
  <!-- no pages found -->
  <div class="mt-5 border__dashed ">
    <v-row>
      <v-col>
        <div class="d-flex pa-5">
          <div
            class="pa-5 w-50"
          >
            <div class="d-flex align-center flex-wrap">
              <img
                :src="require('@/assets/images/page-diff/clip-plus-icon.png')"
                width="60"
              >
              <span class="text-primary mx-2 medium-20">Monitor your first page by  <br> adding a new link</span>
            </div>

            <div class="d-flex mt-4">
              <v-text-field
                v-model="pageURL"
                placeholder="http://"
                hide-details
                outlined
                dense
              ></v-text-field>

              <v-btn
                color="primary"
                class="ml-1 small__btn"
                @click="openJobDialog"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider
            vertical
          ></v-divider>

          <div
            class="pa-5 w-50"
          >
            <span class="regular-14">
              Set up your page alerts by creating your job here. You can:
              <ul>
                <li>Adjust the Area you are monitoring SO we track only things you care about</li>
                <li>Use 'Actions' to customize the workflow on the pages</li>
                <li>Adjust how often you'd like to track so you get timely alerts</li>
              </ul>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiPlus } from '@mdi/js'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      pageURL: null,
      icons: {
        mdiPlus,
      },
    }
  },

  methods: {
    openJobDialog() {
      if (this.pageURL) {
        localStorage.setItem('pageURL', this.pageURL)
      } else {
        localStorage.removeItem('pageURL')
      }
      this.pageURL = null
      this.jobDialogToggle()
    },
    ...mapActions('tools/pageDiff', ['jobDialogToggle']),
  },
}
</script>
<style>
.border__dashed {
  border: 1px dashed rgba(58, 53, 65, 0.23);
  border-radius: 6px;
}
.text-primary {
  color: #c61044;
}

.small__btn {
  min-width: 10px !important;
  width: 10px !important;
}
.w-50 {
  width: 50%;
}

.regular-14 {
  font-weight: normal;
  font-size: 14px;
}
.medium-20 {
  font-weight: 500;
  font-size: 20px;
}
</style>
