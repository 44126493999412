<template>
  <v-card
    class="mb-2 page__container"
    :class="activePage && activePage.id === page.id ? 'active__page' : ''"
  >
    <v-card-text
      class="d-flex flex-wrap"
    >
      <div
        class="page d-flex py-2 "
        style="flex:1"
        @click="setActivePage(page)"
      >
        <div
          class="d-flex  align-start"
        >
          <v-img
            width="50"
            class="mr-4"
            :src="`https://www.google.com/s2/favicons?domain=${page.url}&sz=256`"
          ></v-img>
        </div>
        <div>
          <div>
            <h3 class="page__title">
              {{ page.title ? page.title : '-' }}
            </h3>
            URL: {{ page.url }}
          </div>

          <div
            class="d-flex align-center  pointer mt-2 meta-info "
            @click="setActivePage(page)"
          >
            <div class="mr-5">
              <label>Type of check:</label>
              <span class=" mt-1 text-capitalize">
                {{ page.settings.type }}
              </span>
            </div>
            <div>
              <label>Threshold:</label>
              <span class=" mt-1 text-capitalize">
                {{ resolveThreshold(page.settings.threshold) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class=" d-flex ">
        <div
          class="actions d-flex flex-column justify-space-between  "
          style="min-width:10px"
        >
          <v-chip
            v-if="page.status.includes('error')"
            class="page__status"
          >
            Has errors
          </v-chip>

          <v-chip
            v-else
            disabled
            style="border:none"
            outlined
          ></v-chip>

          <div>
            <v-btn
              icon
              small
              @click="editPage(page)"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>

            <v-btn
              v-if="!page.pivot.is_paused"
              icon
              small
              :loading="pauseLoading"
              @click="pausePage(page,1)"
            >
              <v-icon>{{ icons.mdiPauseCircleOutline }}</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              small
              :loading="pauseLoading"
              @click="pausePage(page,0)"
            >
              <v-icon>{{ icons.mdiPlayCircleOutline }}</v-icon>
            </v-btn>
            <v-btn
              icon
              small
              @click="deleteConfirmation(page)"
            >
              <v-icon>{{ icons.mdiDelete }}</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
    <div
      v-if="activePage && activePage.id === page.id"
      class="active__border"
    >
      <v-icon color="white">
        {{ icons.mdiChevronRight }}
      </v-icon>
    </div>
  </v-card>
</template>

<script>
import apiClient from '@/services/'
import {
  mdiChevronRight,
  mdiClock,
  mdiDelete,
  mdiDotsVertical,
  mdiMonitor,
  mdiPauseCircleOutline,
  mdiPencilOutline,
  mdiPlayCircleOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
    deleteConfirmation: {
      type: Function,
      required: true,
    },
    editPage: {
      type: Function,
      required: true,
    },
  },
  setup(props, { root }) {
    const icons = {
      mdiDotsVertical,
      mdiClock,
      mdiPauseCircleOutline,
      mdiPlayCircleOutline,
      mdiDelete,
      mdiPencilOutline,
      mdiMonitor,
      mdiChevronRight,
    }

    const activePage = computed(() => root.$store.state.tools.pageDiff.activePage)

    const setActivePage = item => {
      root.$store.dispatch('tools/pageDiff/setActivePage', item)
    }
    const pauseLoading = ref(false)
    const pausePage = (page, isPaused) => {
      pauseLoading.value = true
      apiClient
        .pausePage({ id: page.id, is_paused: isPaused })
        .then(response => {
          root.$store.dispatch('tools/pageDiff/fetchPages')
          root.$store.dispatch('fetchUsageLimitsForce')
          root.$store.dispatch('notify', response.data.message)
        })
        .catch(err => {
          root.$store.dispatch('notify', err.response.data.message || err.response.data.error)
        })
        .finally(() => {
          pauseLoading.value = false
        })
    }

    return {
      icons,
      setActivePage,
      pausePage,
      pauseLoading,
      activePage,
      resolveThreshold: threshold => {
        // eslint-disable-next-line eqeqeq
        if (threshold == 0.1) return 'Anything 0.1%'
        if (threshold == 1) return 'Small 1%'
        if (threshold == 10) return 'Medium 10%'
        if (threshold == 25) return 'Large 25%'

        return 'Custom'
      },
    }
  },
}
</script>

<style scoped lang="scss">
.page__container {
  display: flex;

  .active__border {
    width: 30px;
    background: #c81043;
    border: 1px solid #c81043;
    display: flex;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .page {
    cursor: pointer;
    .icon-circle {
      border-radius: 50%;
      padding: 0.6rem;
      background: #f7e1e7;
    }
    .page__title {
      color: rgba(58, 53, 65, 0.87);
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.15px;
    }
  }
  .meta-info {
    font-size: 12px;
  }
  label {
    color: #3a354161;
  }

  .actions {
    width: 100px;
    .v-icon {
      color: rgba(58, 53, 65, 0.32);
    }
    .page__status {
      background: rgba(255, 76, 81, 0.2);
      margin-bottom: 5px;
      height: 29px;
      color: #ff4c51;
    }
  }
}

.active__page {
  border: 1px solid #c81043;
  box-shadow: none !important;
}
.pointer {
  cursor: pointer;
}
</style>
