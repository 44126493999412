<template>
  <v-dialog
    v-model="isDialogVisible"
    height="90%"
    width="750px"
  >
    <v-card>
      <v-card-title>
        <h2 class="headline">
          <v-icon
            size="44"
            color="primary"
            class="mr-2"
          >
            {{ icons.mdiPlusCircleOutline }}
          </v-icon>  Add New Page
        </h2>
        <v-spacer></v-spacer>
        <v-icon
          @click="jobDialogToggle"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="requestError"
          color="error"
          text
          dark
          class="my-4"
        >
          {{ requestError }}
        </v-alert>
      </v-card-text>

      <v-card-text>
        <div class="form-group">
          <label class="regular-12">URL</label>
          <v-text-field
            v-for="(field,index) in textFields"
            :key="index"
            v-model="field.value"
            class="url__field"
            outlined
            dense
            :prepend-inner-icon="icons.mdiLink"
            placeholder="https://domain.com/page-to-monitor"
            hide-details
          >
            <v-icon
              v-if="field.primary"
              slot="append-outer"
              @click="addURL"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <v-icon
              v-else
              slot="append-outer"
              color="red"
              @click="removeURL(index)"
            >
              {{ icons.mdiClose }}
            </v-icon>
          </v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <span class="regular-12 text-uppercase">Settings for this page:</span>
            <v-select
              v-model="settings.type"
              :items="compareTypes"
              item-value="key"
              item-text="value"
            ></v-select>

            <v-select
              v-model="settings.threshold"
              label="Threshold"
              :items="state.thresholdOptions"
            ></v-select>
            <v-slider
              v-if="settings.threshold == 'custom'"
              v-model="customThreshold"
              :label="`Select  ${customThreshold}%`"
              hint="%"
              value="50"
              max="100"
              min="1"
            ></v-slider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <span class="regular-12 text-uppercase">Notifications</span>
            <div
              class="notifications mt-4"
            >
              <div
                class="item"
                :class="notificationSource == 'email' ? 'active' : ''"
                @click="notificationSource = 'email'"
              >
                <img
                  :src="require('@/assets/images/svg/mail-icon.svg')"
                />
                <span class="pr-3">Email</span>
              </div>
              <div
                class="item"
                :class="notificationSource == 'slack' ? 'active' : ''"
                @click="notificationSource = 'slack'"
              >
                <img
                  :src="require('@/assets/images/svg/slack-logo.svg')"
                />
                <span class="pr-3">Salck</span>
              </div>
            </div>

            <div
              v-if="notificationSource != ''"
              class="notification_inputs pa-3"
            >
              <div
                v-if="notificationSource == 'email'"
              >
                <label class="mb-0">To account holder:</label>
                <v-combobox
                  v-model="state.emails"
                  class="mt-0"
                  placeholder="Email"
                  multiple
                  clearable
                  chips
                  hint="Press enter to add"
                  append-icon=""
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text"
                        left
                        v-text="data.item.slice(0, 1).toUpperCase()"
                      ></v-avatar>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

              <v-text-field
                v-else-if="notificationSource == 'slack'"
                v-model="state.slackHook"
                label="Slack Webhook URL"
                placeholder="http://hook-url.example.com"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-alert
          v-if="limits.left < 1"
          border="left"
          dense
          dismissible
          elevation="3"
          text
          type="info"
        >
          loading Limit quota exceeded : Usage left {{ limits.used }}/{{ limits.max }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary letter-spacing-sm regular-14"
          :loading="loading"
          @click="submitHandler"
        >
          Create page
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="jobDialogToggle"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiClient from '@/services'
import { mdiClose, mdiLink, mdiPlus, mdiPlusCircleOutline } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import { mapActions } from 'vuex'

export default {
  setup(props, { root }) {
    const loading = ref(false)
    const activeStep = ref(1)
    const notificationSource = ref('')
    const url = ref('')

    const textFields = reactive([
      {
        primary: true,
        value: null,
      },
    ])

    const isDialogVisible = computed({
      set: () => {
        root.$store.dispatch('tools/pageDiff/jobDialogToggle')
      },
      get: () => {
        const localURL = localStorage.getItem('pageURL')
        if (localURL) textFields[0].value = localURL
        textFields.splice(1)

        return root.$store.state.tools.pageDiff.jobDialog
      },
    })
    const limits = computed(() => root.$store.state.limits.pageShots)

    const state = {
      thresholdOptions: [
        {
          value: '0.1',
          text: 'Anything 0.1%',
        },
        {
          value: '1',
          text: 'Small 1%',
        },
        {
          value: '10',
          text: 'Medium  10%',
        },
        {
          value: '25',
          text: 'Large  25%',
        },
        {
          value: 'custom',
          text: 'Custom',
        },
      ],
      emails: [],
      slackHook: null,
    }
    const customThreshold = ref(50)

    const requestError = ref('')

    const settings = reactive({
      type: 'html',
      threshold: '0.1',
    })

    const submitHandler = () => {
      loading.value = true
      if (settings.threshold === 'custom') {
        settings.threshold = customThreshold.value
      }
      requestError.value = ''
      const payload = {
        url: textFields.map(item => item.value),
        settings: JSON.stringify(settings),
        emails: null,
        slack_webhook_url: null,
      }

      payload.emails = state.emails
      payload.slack_webhook_url = state.slackHook

      apiClient
        .addPageRequest(payload)
        .then(response => {
          textFields.splice(1)
          localStorage.removeItem('pageURL')
          root.$store.dispatch('notify', response.data.message || 'Page added to monitoring successfully!')
          root.$store.dispatch('tools/pageDiff/fetchPages')
          root.$store.dispatch('tools/pageDiff/jobDialogToggle')
          root.$store.dispatch('fetchUsageLimitsForce')
        })
        .catch(err => {
          const errorMessage = err.response.data.error || err.response.data.message
          root.$store.dispatch('notify', `Error : ${errorMessage}`)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      textFields,
      activeStep,
      notificationSource,
      url,
      customThreshold,
      settings,
      isDialogVisible,
      submitHandler,
      loading,
      requestError,
      ...mapActions('tools/pageDiff', ['jobDialogToggle', 'fetchPages']),
      state,
      limits,
      icons: {
        mdiPlusCircleOutline,
        mdiClose,
        mdiLink,
        mdiPlus,
      },
      compareTypes: [
        { key: 'html', value: 'Visual' },
        { key: 'text', value: 'Text' },
        { key: 'title', value: 'Title' },
      ],
      addURL: () => {
        textFields.push({
          primary: false,
          value: null,
        })
      },
      removeURL: index => {
        textFields.splice(index, 1)
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/page-diff/page-form.scss';
</style>
