<template>
  <div>
    <v-card>
      <v-card-title
        v-if="activePage"
        class="word-break-inherit flex-nowrap align-start"
      >
        <v-icon
          class="pointer mr-2"
          @click="setActivePage(null)"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        <div class="d-flex justify-end align-baseline flex-wrap">
          <h5 class="medium-20 mb-3">
            {{ activePage.title }}
          </h5>
          <v-spacer></v-spacer>
          <span class="text--disabled text-small ml-3">Monitoring <span class="text-uppercase">{{ activePage.settings.type }}</span> changes > {{ activePage.settings.threshold }}%</span>
        </div>
      </v-card-title>
      <v-card-title v-else>
        Activity Timeline
      </v-card-title>
      <v-card-text v-if="loading.activityTimeline">
        <content-loader></content-loader>
      </v-card-text>

      <div v-else>
        <v-card-text
          v-if="activities && activities.data && activities.data.length"
        >
          <v-timeline
            dense
            class="timeline-custom-dense timeline-custom-dots"
            :class="activePage ? '' : 'timeline__removeLine'"
          >
            <v-timeline-item
              v-for="(diff,key) in activities.data"
              :key="key"
              small
              :color="resolveColor(diff.diff_page_id,key)"
            >
              <h5 v-if="activePage">
                {{ moment(diff.created_at).fromNow() }}
              </h5>

              <div
                v-else
                class="d-flex align-center align-baseline flex-wrap"
              >
                <h5 class="medium-16 mb-2">
                  {{ diff.pageTitle }}
                </h5>
                <v-spacer></v-spacer>
                <span class="text-no-wrap regular-12">{{ moment(diff.created_at).fromNow() }}</span>
              </div>

              <div class="preview__image mt-4">
                <div
                  v-lazy:background-image="__diskImage(diff.screenshot_path)"
                  class="image"
                  @click="previewCompareDialog(diff)"
                ></div>
                <div
                  v-if="diff.threshold < 100"
                  class="mt-5"
                >
                  {{ diff.threshold }}% has been changed
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <div
            v-if="activities.next_page_url"
            class="text-center"
          >
            <v-btn
              :loading="loading.activityTimelineMore"
              @click="fetchMoreActivites"
            >
              Load more
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text
          v-else
          class="text-center"
        >
          <v-img :src="require('@/assets/images/page-diff/no-activity.png')"></v-img>
          <h4 class="mt-3">
            So far, no changes have been found
          </h4>
          <v-card-text class="pa-6 pt-2">
            Here you will see all changes of all monitored pages.
          </v-card-text>
        </v-card-text>
      </div>
    </v-card>

    <compare-dialog
      v-if="preview.page"
      :compare-dialog-visible.sync="compareDialogVisible"
      :page="preview.page"
      :diff="preview.diff"
      :disable-action="false"
    />
  </div>
</template>

<script>
import apiClient from '@/services'
import { mdiArrowLeft, mdiClock, mdiDotsVertical } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import { mapActions } from 'vuex'
import CompareDialog from './CompareDialog.vue'

export default {
  components: {
    CompareDialog,
    ContentLoader,
  },
  setup(props, { root }) {
    const loading = computed(() => root.$store.state.tools.pageDiff.loading)
    const activePage = computed(() => root.$store.state.tools.pageDiff.activePage)
    const activities = computed(() => root.$store.state.tools.pageDiff.activities)
    const pages = computed(() => root.$store.state.tools.pageDiff.pages)

    const preview = reactive({
      diff: null,
      page: null,
    })
    const compareDialogVisible = ref(false)
    const previewCompareDialog = diff => {
      preview.page = pages.value.find(item => item.id === diff.diff_page_id)
      preview.diff = null
      compareDialogVisible.value = true

      // fetch diff
      apiClient.fetchDiffVersion(diff.id).then(res => {
        preview.diff = res.data
      })
    }

    const colors = reactive({
      // unused: ['#16B1FF', '#56CA00', 'primary', 'info', 'warning', 'error', '#00FF00'],
      unused: ['primary'],
      used: [],
    })

    return {
      loading,
      activities,
      activePage,
      compareDialogVisible,
      preview,
      previewCompareDialog,
      icons: {
        mdiDotsVertical,
        mdiClock,
        mdiArrowLeft,
      },
      resolveColor: (pageId, key) => {
        // for active page , for first item show it green other red
        if (activePage.value && key === 0) return '#00FF00'
        if (activePage.value) return '#FF0000'

        if (colors.used.length && colors.used.find(item => item.pageId === pageId)) {
          return colors.used.find(item => item.pageId === pageId).color
        }
        if (colors.unused.length) {
          colors.used.push({
            pageId,
            color: colors.unused.pop(),
          })
        } else {
          // colors.unused = ['#16B1FF', '#56CA00', 'primary', 'info', 'warning', 'error', '#00FF00']
          colors.unused = ['primary']

          colors.used.push({
            pageId,
            color: colors.unused.pop(),
          })
        }

        return colors.used.find(item => item.pageId === pageId).color
      },
      ...mapActions('tools/pageDiff', ['setActivePage', 'fetchMoreActivites']),
    }
  },
  created() {
    this.$store.dispatch('tools/pageDiff/fetchActivites')
  },
}
</script>

<style scoped lang="scss">
.preview__image {
  // background: #f9fafb;
  padding: 12px;

  .image {
    background-size: cover;
    width: 230px;
    height: 120px;
    cursor: pointer;
  }
}

.text-small {
  font-size: 10pt;
}
.word-break-inherit {
  word-break: inherit;
}
.timeline__removeLine::before {
  display: none;
}
</style>
