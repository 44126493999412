<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center"
      >
        <img
          :src="require('@/assets/images/page-diff/icon.svg')"
          width="50"
        >
        <h3 class="heading mx-2">
          Website Monitoring
        </h3>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <!-- header -->
        <div class="d-flex flex-wrap align-center">
          <h4 class="sub__heading mr-15">
            Monitored Pages <span v-if="pages">({{ limits.used + '/' + limits.max }})</span>
          </h4>

          <v-text-field
            v-model="searchField"
            class="my-2 mr-1"
            outlined
            dense
            label="Search"
            hide-details
            :prepend-inner-icon="icons.mdiMagnify"
          ></v-text-field>

          <v-btn
            outlined
            color="primary"
            @click="jobDialogToggle"
          >
            <v-icon class="m-0">
              {{ icons.mdiPlus }}
            </v-icon>
            Add Page
          </v-btn>
        </div>

        <div
          v-if="!loading && pages && pages.length"
          dense
          class="mt-3"
        >
          <page-list-item
            v-for="(item,key) in pages"
            :key="key"
            :delete-confirmation="deleteConfirmation"
            :edit-page="editPage"
            :page="item"
          />
        </div>
        <no-page-found v-else-if="!loading && pages"></no-page-found>
        <content-loader
          v-else
          class="mt-5"
          primary-color="white"
        >
          <rect
            x="0"
            y="0"
            rx="3"
            ry="3"
            width="100%"
            height="48"
          />

          <rect
            x="0"
            y="55"
            rx="3"
            ry="3"
            width="100%"
            height="48"
          />
        </content-loader>
      </v-col>

      <v-col
        sm="12"
        md="4"
      >
        <activity-timeline />
      </v-col>
    </v-row>
    <NewPageDialog />
    <EditPageDialog
      v-if="dialogs.selectedPage && dialogs.edit"
      :dialog.sync="dialogs.edit"
      :page="dialogs.selectedPage"
      :delete-confirmation="deleteConfirmation"
    />
    <DeletePageDialog
      :delete-dialog.sync="dialogs.delete"
      :page="dialogs.selectedPage"
    />
  </div>
</template>

<script>
import { mdiClock, mdiDotsVertical, mdiMagnify, mdiPlus, mdiPlusCircleOutline, mdiRefreshCircle, mdiWeb } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import 'img-comparison-slider'
import { ContentLoader } from 'vue-content-loader'
import { mapActions } from 'vuex'
import ActivityTimeline from './components/ActivityTimeline.vue'
import DeletePageDialog from './components/DeletePageDialog.vue'
import EditPageDialog from './components/EditPageDialog.vue'
import NewPageDialog from './components/NewPageDialog.vue'
import NoPageFound from './components/NoPageFound.vue'
import PageListItem from './components/PageListItem.vue'

export default {
  components: {
    NewPageDialog,
    ActivityTimeline,
    PageListItem,
    NoPageFound,
    DeletePageDialog,
    EditPageDialog,
    ContentLoader,
  },

  async created() {
    await this.fetchPages()
    await this.fetchUsageLimits()
  },

  setup(props, { root }) {
    const searchField = ref('')

    const pages = computed(() => {
      if (searchField.value) {
        return root.$store.state.tools.pageDiff.pages.filter(page => {
          if (page.title && page.title.toLowerCase().indexOf(searchField.value) > -1) return true
          if (page.url && page.url.indexOf(searchField.value) > -1) return true

          return false
        })
      }

      return root.$store.state.tools.pageDiff.pages
    })

    const activePage = computed(() => root.$store.state.tools.pageDiff.activePage)
    const limits = computed(() => root.$store.state.limits.pageShots)
    const limitsReady = computed(() => root.$store.state.limits.loaded)

    // const loading = computed(() => root.$store.state.loading)
    const loading = computed(() => root.$store.state.tools.pageDiff.loading.default)

    const dialogs = reactive({
      delete: false,
      edit: false,
      selectedPage: null,
    })

    const preview = ref(false)

    const icons = {
      mdiRefreshCircle,
      mdiPlusCircleOutline,
      mdiMagnify,
      mdiClock,
      mdiWeb,
      mdiDotsVertical,
      mdiPlus,
    }

    return {
      icons,
      pages,
      activePage,
      preview,
      ...mapActions('tools/pageDiff', ['jobDialogToggle', 'fetchPages']),
      ...mapActions(['fetchUsageLimits']),
      limits,
      limitsReady,
      loading,
      dialogs,
      searchField,
      deleteConfirmation: page => {
        dialogs.edit = false
        dialogs.delete = true
        dialogs.selectedPage = page
      },
      editPage: page => {
        dialogs.edit = true
        dialogs.selectedPage = page
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.page__status {
  float: right;
  color: white;
  padding: 2px 16px;
  font-size: 7pt;
  border-radius: 1rem;
}
.heading {
  font-size: 24px;
  font-weight: 500;
  color: rgba(58, 53, 65, 0.87);
}

.sub__heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
</style>
