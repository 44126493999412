<template>
  <v-dialog
    v-model="isDialogVisible"
    height="90%"
    width="750px"
  >
    <v-card v-if="page">
      <v-card-title>
        <h2
          class="headline d-flex align-center word-break-normal"
          style="width:90%"
        >
          <div>
            <v-img
              width="50"
              class="mr-2"
              :src="`https://www.google.com/s2/favicons?domain=${page.url}&sz=256`"
            ></v-img>
          </div>
          <span>{{ page.title }}</span>
        </h2>
        <v-spacer></v-spacer>
        <v-icon
          @click="$emit('update:dialog',false)"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="requestError"
          color="error"
          text
          dark
          class="my-4"
        >
          {{ requestError }}
        </v-alert>
      </v-card-text>

      <v-card-text>
        <div class="form-group">
          <label class="regular-12">URL</label>
          <v-text-field
            v-model="page.url"
            class="url__field"
            outlined
            dense
            disabled
            :prepend-inner-icon="icons.mdiLink"
            placeholder="https://"
            hide-details
          ></v-text-field>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="form-group d-flex flex-wrap">
          <div
            class="flex-grow-1"
          >
            <v-img
              :src="__diskImage(screenshot.src)"
              max-width="200"
              max-height="200"
              position="center top"
            ></v-img>
          </div>
          <div
            class="pa-5 flex-grow-1"
          >
            <div class="d-flex ">
              <span class="text-uppercase text--secondary">Info</span>
              <v-spacer></v-spacer>
              <span class="text--disabled regular-12">Checked {{ screenshot.takenAt }} </span>
            </div>
            <ul style="color:#3A3541">
              <li class=" mt-4">
                Taken {{ screenshot.takenAt }}
              </li>
              <li><strong>{{ state.versionsCount }}</strong> previous versions tracked</li>
            </ul>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <span class="regular-12 text-uppercase">Settings for this page:</span>
            <v-select
              v-model="page.settings.type"
              :items="compareTypes"
              item-value="key"
              item-text="value"
            ></v-select>

            <v-select
              v-model="page.settings.threshold"
              label="Threshold"
              :items="state.thresholdOptions"
            ></v-select>
            <v-slider
              v-if="page.settings.threshold == 'custom'"
              v-model="customThreshold"
              :label="`Select  ${customThreshold}%`"
              hint="%"
              value="50"
              max="100"
              min="1"
            ></v-slider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <span class="regular-12 text-uppercase">Notifications</span>
            <div
              class="notifications mt-4"
            >
              <div
                class="item"
                :class="notificationSource == 'email' ? 'active' : ''"
                @click="notificationSource = 'email'"
              >
                <img
                  :src="require('@/assets/images/svg/mail-icon.svg')"
                />
                <span class="pr-3">Email</span>
              </div>
              <div
                class="item"
                :class="notificationSource == 'slack' ? 'active' : ''"
                @click="notificationSource = 'slack'"
              >
                <img
                  :src="require('@/assets/images/svg/slack-logo.svg')"
                />
                <span class="pr-3">Salck</span>
              </div>
            </div>

            <div
              v-if="notificationSource != ''"
              class="notification_inputs pa-3"
            >
              <div
                v-if="notificationSource == 'email'"
              >
                <label class="mb-0">To account holder:</label>
                <v-combobox
                  v-model="state.emails"
                  class="mt-2"
                  label="Email"
                  clearable
                  multiple
                  chips
                  hint="Press enter to add"
                  append-icon=""
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text"
                        left
                        v-text="data.item.slice(0, 1).toUpperCase()"
                      ></v-avatar>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </div>

              <v-text-field
                v-else-if="notificationSource == 'slack'"
                v-model="state.slackHook"
                label="Slack Webhook URL"
                placeholder="http://hook-url.example.com"
              ></v-text-field>
            </div>

            <!-- <div
              v-if="notificationAlert"
              class="notification__alert"
            >
              <v-icon
                size="17"
                color="red"
              >
                {{ icons.mdiInformationOutline }}
              </v-icon> You need to add some type of notification.
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-alert
          v-if="limits.left < 1"
          border="left"
          dense
          dismissible
          elevation="3"
          text
          type="info"
        >
          loading Limit quota exceeded : Usage left {{ limits.used }}/{{ limits.max }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="limits.left < 1 ? true : false"
          color="primary"
          :loading="loading"
          @click="submitHandler"
        >
          Save
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:dialog',false)"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          class="text-capitalize delete__btn"
          plain
          text
          @click="deleteConfirmation(page)"
        >
          <v-icon
            size="17"
          >
            {{ icons.mdiClose }}
          </v-icon> Delete Page
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apiClient from '@/services'
import { mdiClose, mdiInformationOutline, mdiLink, mdiPlusCircleOutline } from '@mdi/js'
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import moment from 'moment'

export default {
  props: {
    page: {
      type: Object,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    deleteConfirmation: {
      type: Function,
      required: true,
    },
  },

  setup(props, { root, emit }) {
    const isDialogVisible = computed({
      set: () => {
        emit('update:dialog', false)
      },
      get: () => props.dialog,
    })

    const loading = ref(false)
    const screenshot = reactive({
      src: null,
      takenAt: null,
      lastChecked: null,
    })
    const { page } = props

    const state = reactive({
      thresholdOptions: [
        {
          value: '0.1',
          text: 'Anything 0.1%',
        },
        {
          value: '1',
          text: 'Small 1%',
        },
        {
          value: '10',
          text: 'Medium  10%',
        },
        {
          value: '25',
          text: 'Large  25%',
        },
        {
          value: 'custom',
          text: 'Custom',
        },
      ],
      emails: [],
      slackHook: null,
      versionsCount: null,
    })
    onMounted(() => {
      loading.value = true
      apiClient
        .pageVersionStats(page.id)
        .then(response => {
          const previousVersion = response.data.previous_version
          screenshot.src = previousVersion.screenshot_path
          screenshot.takenAt = moment(previousVersion.screenshot_path).fromNow()
          state.versionsCount = response.data.versions_count
        })
        .finally(() => {
          loading.value = false
        })
    })

    // if (page && page.diff_versions.length) {
    //   const diff = page.diff_versions[0]
    //   screenshot.src = diff.screenshot_path
    //   screenshot.takenAt = moment(diff.created_at).fromNow()
    // }

    const notificationSource = ref('')
    if (page.pivot.emails && page.pivot.emails.length) {
      notificationSource.value = 'email'
      state.emails = page.pivot.emails
    }
    if (page.pivot.slack_webhook_url) {
      notificationSource.value = 'slack'
      state.slackHook = page.pivot.slack_webhook_url
    }

    const limits = computed(() => root.$store.state.limits.pageShots)

    const customThreshold = ref(50)

    const requestError = ref('')

    const submitHandler = () => {
      // if (notificationAlert === true) {
      //   root.$store.dispatch('notify', 'Form validation failed')

      //   return
      // }
      loading.value = true

      if (page.settings.threshold === 'custom') {
        page.settings.threshold = customThreshold.value
      }
      requestError.value = ''
      const payload = {
        id: page.id,
        settings: page.settings,
        emails: null,
        slack_webhook_url: null,
      }

      payload.emails = state.emails
      payload.slack_webhook_url = state.slackHook

      apiClient
        .updatePageRequest(payload)
        .then(response => {
          root.$store.dispatch('notify', response.data.message || 'Page updated successfully!')
          root.$store.dispatch('tools/pageDiff/fetchPages')
          root.$store.dispatch('fetchUsageLimitsForce')
        })
        .catch(err => {
          const errorMessage = err.response.data.error || err.response.data.message
          root.$store.dispatch('notify', `Error : ${errorMessage}`)
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      screenshot, // object
      notificationSource,
      customThreshold,
      submitHandler,
      loading,
      requestError,
      state,
      limits,
      icons: {
        mdiPlusCircleOutline,
        mdiClose,
        mdiLink,
        mdiInformationOutline,
      },
      compareTypes: [
        { key: 'html', value: 'Visual' },
        { key: 'text', value: 'Text' },
        { key: 'title', value: 'Title' },
      ],

      // notificationAlert,
      isDialogVisible,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/page-diff/page-form.scss';
.delete__btn {
  font-weight: 600;
  font-size: 14px;
}
</style>
