<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    height="90%"
    scrollable
    :persistent="disableAction"
  >
    <v-card :loading="!diff">
      <v-card-title class="flex-column">
        <div
          class="d-flex w-100 mb-1"
        >
          <div>
            <v-img
              width="50"
              class="mr-4"
              :src="`https://www.google.com/s2/favicons?domain=${page.url}&sz=256`"
            ></v-img>
          </div>
          <div class="word-break-normal">
            {{ page.title }} <span class="text--secondary font-weight-light"> - Recent Changes</span><br>
            <span style="font-size:14px">
              {{ page.url }}
            </span>
          </div>
          <v-spacer></v-spacer>

          <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  class="mx-3"
                  v-on="on"
                  @click="openInNewWindow"
                >
                  {{ icons.mdiOpenInNew }}
                </v-icon>
              </template>
              <span>Open in new window</span>
            </v-tooltip> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                @click="disableAction ? $router.push({name: 'page-diff'}) : $emit('update:compareDialogVisible', false) "
                v-on="on"
              >
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <span>Close</span>
          </v-tooltip>
        </div>

        <div
          v-if="diff"
          class="text-center"
        >
          <v-btn-toggle
            v-model="tab"
            class="tab__group flex-wrap"
          >
            <v-btn
              outlined
              class="border text-capitalize"
            >
              Visual Changes
            </v-btn>
            <v-btn
              outlined
              class=" text-capitalize"
              :disabled="!diff.prev_diff"
            >
              Text Changes
            </v-btn>
            <!-- <v-btn
              outlined
              class="border text-capitalize"
            >
              Element Changes
            </v-btn> -->
          </v-btn-toggle>
        </div>
      </v-card-title>
      <v-card-text v-if="diff">
        <v-tabs
          v-model="tab"
          centered
          outlined
          class="tabs"
        >
          <v-tab-item>
            <div
              v-if="diff.prev_diff && diff.prev_diff.screenshot_path"
              :style="{ width: '100%' }"
            >
              <div
                class="dates text-uppercase"
              >
                <div>{{ moment(diff.prev_diff.created_at).format('MMM DD') }}</div>
                <div>{{ moment(diff.created_at).format('MMM DD') }}</div>
              </div>
              <img-comparison-slider
                class="coloured-slider"
                hover="hover"
              >
                <img
                  slot="first"
                  width="100%"
                  :src="__diskImage(diff.prev_diff.screenshot_path)"
                >
                <img
                  slot="second"
                  width="100%"
                  :src="__diskImage(diff.screenshot_path)"
                >

                <svg
                  slot="handle"

                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="26.6255"
                    cy="26.6255"
                    r="26.6255"
                    fill="#C81043"
                    fill-opacity="0.5"
                  />
                </svg>
              </img-comparison-slider>
            </div>

            <div
              v-else
            >
              <div
                class="dates text-uppercase"
              >
                <div>{{ moment(diff.created_at).format('MMM DD') }}</div>
              </div>

              <img
                class="preview__image"
                :src="__diskImage(diff.screenshot_path)"
              />
            </div>
          </v-tab-item>

          <v-tab-item class="compare__html-tab">
            <div
              v-if="diff.prev_diff && diff.prev_diff.screenshot_path"
              class="dates"
            >
              <div> {{ moment(diff.prev_diff.created_at).format('MMM DD') }} - {{ moment(diff.created_at).format('MMM DD') }}</div>
            </div>
            <div
              class="text__compare"
              v-html="compareHTML"
            ></div>
          </v-tab-item>
          <v-tab-item>
            Pending
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-text v-else>
        <content-loader></content-loader>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiOpenInNew } from '@mdi/js'
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader,
  },
  props: ['page', 'compareDialogVisible', 'diff', 'disableAction'],
  data() {
    return {
      hover: true,
      tab: 0,
      icons: {
        mdiClose,
        mdiOpenInNew,
      },
    }
  },
  computed: {
    compareHTML() {
      if (this.diff.compareHTML) {
        return this.diff.compareHTML
      }

      return null
    },

    htmlPage() {
      return this.diff.html

      // const htmlLink = this.__diskImage(this.diff.screenshot_path.replace('png', 'html'))

      // return htmlLink
    },
    dialog: {
      set() {
        this.$emit('update:compareDialogVisible', false)
      },
      get() {
        return this.compareDialogVisible
      },
    },
  },

  watch: {
    tab: {
      handler(val) {
        if (val === 2) {
          this.fixIframe()
        }
      },
    },
  },
  created() {
    console.log(this.compareDialogVisible)
  },

  methods: {
    fixIframe: () => {
      console.log(this.$refs.iframeRef)
    },
    openInNewWindow() {
      const routeData = this.$router.resolve({ name: 'compare-dialog-only', params: { diffId: this.diff.id } })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

<style  lang="scss">
//tab margin temporary fix
iframe {
  border: none;
}
.v-slide-group {
  display: none !important;
}
.preview__image {
  width: 100%;
}
.tab__group {
  margin-bottom: 1rem;
}

.tabs {
  background: #e7e7e8;
  .compare__html-tab {
    border: 1px solid rgba(128, 128, 128, 0.206);
    border-radius: 4px;
  }
  .dates {
    background: #e0e0e1;
    display: flex;
    div {
      padding: 10px;
      text-align: center;
      flex-grow: 1;
      width: 50%;
    }
  }
}

.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  box-shadow: none !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn.border__center {
  border-left: 0 !important;
  border-right: 0 !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border: 1px solid #c8104380 !important;
  border-color: 1px solid #c8104380 !important;
}

.v-btn--active {
  // background: var(--v-primary-base) !important;
  background: #c81043 !important;
  color: white !important;
}

.coloured-slider {
  --divider-color: rgba(58, 53, 65, 0.2);
  // --default-handle-color: rgba(200, 16, 67, 0.5);
  // --default-handle-shadow: 0px 0px 5px white;
  // --default-handle-width: clamp(20px, 5vw, 100px);
  // --divider-shadow: 0px 0px 5px #c81043;
}

.word-break-normal {
  word-break: normal;
}
.tab__group .v-btn {
  color: #c81043;
}

// table diff

.diff-wrapper.diff {
  background: #f7f7f7;
  border-collapse: collapse;
  border-spacing: 0;
  color: black;
  // empty-cells: show;
  // font-family: monospace;
  font-size: 13px;
  width: 100%;
  // word-break: break-all;
}

.diff-wrapper.diff th {
  font-weight: 700;
}

.diff-wrapper.diff td,
.diff-wrapper.diff th {
  border-collapse: separate;
  border: none;
  padding: 1px 2px;
}

.diff-wrapper.diff td:empty:after,
.diff-wrapper.diff th:empty:after {
  content: ' ';
  visibility: hidden;
}

.diff-wrapper.diff td a,
.diff-wrapper.diff th a {
  color: #000;
  cursor: inherit;
  pointer-events: none;
}

.diff-wrapper.diff thead th {
  border-bottom: 1px solid rgba(128, 128, 128, 0.206);
  padding: 4px;
  text-align: left;
}

.diff-wrapper.diff tbody.skipped {
  border-top: 1px solid rgba(128, 128, 128, 0.206);
}

.diff-wrapper.diff tbody.skipped td,
.diff-wrapper.diff tbody.skipped th {
  display: none;
}

.diff-wrapper.diff tbody th {
  border-right: 1px solid rgba(128, 128, 128, 0.206);
  text-align: right;
  vertical-align: top;
  width: 4em;
}

.diff-wrapper.diff tbody th.sign {
  border-right: none;
  padding: 1px 0;
  text-align: center;
  width: 1em;
}

.diff-wrapper.diff.diff-html {
  // white-space: pre-wrap;
  width: 100%;
  word-break: normal;
  overflow-wrap: anywhere;
  white-space: pre-wrap !important;
}

// .diff-wrapper.diff.diff-html.diff-combined .change.change-rep .rep {
//   white-space: normal;
// }

// .diff-wrapper.diff.diff-html .change .rep {
//   background: #fef6d9;
// }

.diff-wrapper.diff.diff-html .change .old.none,
.diff-wrapper.diff.diff-html .change .new.none,
.diff-wrapper.diff.diff-html .change .rep.none {
  background: transparent;
  cursor: not-allowed;
  display: none;
}

.diff-wrapper.diff.diff-html .change ins {
  font-weight: 700;
  text-decoration: none;
  font-style: italic;
}
.diff-wrapper.diff td del {
  color: red;
  text-decoration: none;
  background: #fdd;
}
.diff-wrapper.diff td ins {
  color: green;
  background: #dfd;
  text-decoration: none;
}

.diff-wrapper.diff td {
  vertical-align: baseline;
  padding: 10px;
  color: rgba(58, 53, 65, 0.87);
}
.diff-wrapper.diff td.old {
  width: 50%;
  max-width: 50%;
  border-right: 1px solid rgba(128, 128, 128, 0.206);
}
.diff-wrapper.diff td.new {
  width: 50%;
  max-width: 50%;
}

.diff-wrapper.diff td.old,
.diff-wrapper.diff td.new {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 22px;
  /* or 160% */
  word-break: break-all !important;
  letter-spacing: 0.15px;
}

.n-old,
.n-new {
  display: none;
}
</style>
