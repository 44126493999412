<template>
  <v-dialog
    v-model="deleteDialog"
    persistent
    max-width="400"
  >
    <v-card class="pa-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:delete-dialog',false)">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-text class="text-center pb-8 px-8">
        <h3 class="delete__message">
          Do you really want to delete  page from your Monitoring pages?
        </h3>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="deletePage"
        >
          Delete Page
        </v-btn>
        <v-btn
          @click="$emit('update:deleteDialog',false)"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiClose } from '@mdi/js'

export default {
  props: {
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      icons: {
        mdiClose,
      },
    }
  },

  methods: {
    deletePage() {
      this.loading = true
      this.$store.dispatch('tools/pageDiff/deletePage', this.page).then(() => {
        this.loading = false
        this.$emit('update:deleteDialog', false)
      })
    },
  },
}
</script>

<style >
.delete__message {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
}
</style>
